<template>
  <div>
    <AdminReservationSearchParams @search="search" />
    <AdminReservationSearchResults/>
  </div>
</template>

<script>
import AdminReservationSearchParams from "@/components/admin/reservationsearch/AdminReservationSearchParams.vue";
import AdminReservationSearchResults from "@/components/admin/reservationsearch/AdminReservationSearchResults.vue";
export default {
  name: "AdminReservationSearch",
  title: "Admin - Reservation Search",
  components: {
    AdminReservationSearchParams,
    AdminReservationSearchResults
  },
  computed: {
    customerId() {
      return this.$route.query.customerId;
    },
    searchCriteria: {
      get() {
        return this.$store.getters["admin/reservationSearchParams"] ?? {};
      },
      set(newValue) {
        this.$store.commit("admin/setReservationSearchParams", {
          ...newValue
        });
      }
    }
  },
  methods: {
    search(searchCriteria) {
      this.searchCriteria = JSON.parse(JSON.stringify(searchCriteria));
    }
  },
  mounted() {
    if (this.customerId) {
      this.searchCriteria = { ...this.searchCriteria, customerId: this.customerId }
    }
    if (this.$route.query.locationId) {
      this.searchCriteria = { ...this.searchCriteria, locationIds: [this.$route.query.locationId] }
    }
    if (this.$route.query.arrivalDate) {
      this.searchCriteria = { ...this.searchCriteria, arrivalDate: this.$route.query.arrivalDate }
    }
    if (this.$route.query.departureDate) {
      this.searchCriteria = { ...this.searchCriteria, departureDate: this.$route.query.departureDate }
    }
  }
};
</script>
