<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title mb-0">Search Results</div>
    </div>
    <div class="card-body">
      <div class="d-flex mb-2">
        <div class="align-self-center">Items Per Page:</div>
        <div class="ml-2">
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
            aria-label="Items Per Page"
          ></b-form-select>
        </div>
      </div>
      <b-table
        ref="table"
        striped
        :fields="fields"
        :items="loadData"
        empty-text="No Reservation Results..."
        empty-filtered-text="No Reservation Results..."
        show-empty
        stacked="xl"
        no-local-sorting
        :current-page="currentPage"
        :per-page="perPage"
        bordered
        sort-icon-left
        small
      >
        <template v-slot:cell(reservationStatus)="data">
          <div>
            <span class="mr-2">{{ data.item.reservationStatus }}</span>
            <b-badge
              class="chargedback-badge"
              v-if="data.item.paymentChargebackCompleted"
              >Payment Chargedback</b-badge
            >
          </div>
        </template>
        <template v-slot:cell(id)="data">
          <button
            class="btn btn-primary btn-sm m-1"
            @click="viewReservation(data.item.id)"
          >
            View
          </button>
        </template>
      </b-table>
      <div class="d-flex">
        <div class="mr-auto">
          Showing
          {{ perPage * currentPage - perPage + (totalRows > 0 ? 1 : 0) }} to
          {{ perPage * currentPage - perPage + perPageDisplay }} of
          {{ totalRows }} entries
        </div>
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SpotReservationService from "@/services/SpotReservationService.js";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";
import moment from "moment";

export default {
  name: "AdminReservationSearchResults",
  mixins: [checkPermissionMixin],
  computed: {
    ...mapGetters("tenant", ["tenantId"]),
    searchParams() {
      return this.$store.getters["admin/reservationSearchParams"] ?? {};
    }
  },
  methods: {
    loadData(ctx) {
      let paging = {
        pageNumber: ctx.currentPage,
        itemsPerPage: ctx.perPage,
        sortField: ctx.sortBy,
        sortDescending: ctx.sortDesc
      };
      const spotReservationService = new SpotReservationService(this.tenantId);
      return spotReservationService
        .search(this.searchParams, paging)
        .then(response => {
          this.currentPage = response.searchResults.pageNumber;
          this.totalRows = response.searchResults.totalItems;
          this.perPageDisplay = response.searchResults.itemsPerPage;
          return response.searchResults.data;
        });
    },
    viewReservation(id) {
      this.$router.push(`/admin/reservation-details/${id}`).catch(() => {});
    }
  },
  watch: {
    searchParams() {
      this.$refs.table.refresh();
      this.currentPage = 1;
    }
  },
  data() {
    return {
      fields: [
        { key: "reservationNumber", sortable: true },
        { key: "reservationStatus", sortable: true },
        { key: "park", sortable: true },
        { key: "spot", sortable: true },
        { key: "loop", sortable: true },
        { key: "customerName", sortable: true },
        { key: "primaryOccupantName", sortable: true },
        { key: "arrivalDate", sortable: true },
        { key: "departureDate", sortable: true },
        {
          key: "transactionDate",
          sortable: true,
          formatter: value => {
            return moment
              .utc(value, "MM/DD/YYYY HH:mm:ss")
              .local()
              .format("MM/DD/YYYY");
          }
        },
        { key: "id", label: "Actions/Options" }
      ],
      currentPage: 1,
      totalRows: 25,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      perPageDisplay: 25
    };
  },
  mounted(){
    this.$refs.table.refresh();
  }
};
</script>
<style scoped>
.chargedback-badge {
  background-color: #1078a8;
}
</style>
