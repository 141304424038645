<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title mb-0">Reservation Search</div>
    </div>
    <div class="card-body">
      <ValidationObserver ref="reservationSearch">
        <form @submit.prevent="search" @keyup.enter="search">
          <FormErrorAlert
            v-if="errors.length > 0"
            :errors="errors"
            :formRef="this.$refs.reservationSearch"
          />
          <div class="form-row">
            <div class="col-md-3 col-sm-12 form-group text-left">
              <TextInput
                rules="min:3|tenantSiteName|max:40"
                name="Reservation Number"
                id="reservationNumber"
                v-model="searchParams.reservationNumber"
              />
            </div>
            <div class="col-md-3 col-sm-12 form-group text-left">
              <TextInput
                rules="min:3|tenantSiteName|max:40"
                name="Confirmation Number"
                id="confirmationNumber"
                v-model="searchParams.confirmationNumber"
              />
            </div>
            <div class="col-md-3 col-sm-12 form-group text-left">
              <label for="reservationStatus">
                <span>Reservation Status</span>
              </label>
              <multiselect
                v-model="selectedStatuses"
                label="name"
                track-by="id"
                :multiple="true"
                id="reservationStatus"
                ref="reservationStatus"
                :clear-on-select="false"
                :preserve-search="true"
                open-direction="bottom"
                placeholder="Select Status(s)"
                :options="statuses"
                @open="openedStatuses"
                :loading="loadingStatuses"
              >
                <template slot="tag" slot-scope="{ option, remove }">
                  <span class="multiselect__tag" :key="option.id">
                    <span>{{ option.name }}</span>
                    <i
                      tabindex="1"
                      @keypress.enter.prevent="remove(option)"
                      @mousedown.prevent="remove(option)"
                      class="multiselect__tag-icon"
                    ></i>
                  </span>
                </template>
              </multiselect>
            </div>
            <div class="col-md-3 col-sm-12 form-group">
              <div class="row">
                <div class="col-md-6 text-left">
                  <TextInput
                    rules="min:3|tenantSiteName|max:40"
                    name="TPE Order Number"
                    id="orderNumber"
                    v-model="searchParams.tpeOrderNumber"
                  />
                </div>
                <div class="col-md-6 text-left mt-4 pt-2 pl-4">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="searchParams.paymentChargedback"
                    id="paymentChargedback"
                    v-model="searchParams.paymentChargedback"
                  />
                  <label class="form-check-label" for="paymentChargedback"
                    >Payment Chargedback
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-3 col-sm-12 form-group text-left">
              <TextInput
                rules="min:2|customerName|max:30"
                name="Customer First Name"
                id="customerFirstName"
                v-model="searchParams.customerFirstName"
              />
            </div>
            <div class="col-md-3 col-sm-12 form-group text-left">
              <TextInput
                rules="min:2|customerName|max:30"
                name="Customer Last Name"
                id="customerLastName"
                v-model="searchParams.customerLastName"
              />
            </div>
            <div class="col-md-3 col-sm-12 form-group text-left">
              <TextInput
                rules="maskNumMin:3"
                name="Customer Phone"
                id="searchPhone"
                v-model="searchParams.customerPhoneNumber"
                mask="(###) ###-####"
                placeholder="(___) ___-____"
              />
            </div>
            <div class="col-md-3 col-sm-12 form-group text-left">
              <TextInput
                rules="min:3|max:256"
                name="Customer Email"
                id="customerEmailAddress"
                v-model="searchParams.customerEmail"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-3 col-sm-12 form-group text-left">
              <TextInput
                rules="min:2|customerName|max:30"
                name="Primary Occupant First Name"
                id="primaryOccupantFirstName"
                v-model="searchParams.primaryOccupantFirstName"
              />
            </div>
            <div class="col-md-3 col-sm-12 form-group text-left">
              <TextInput
                rules="min:2|customerName|max:30"
                name="Primary Occupant Last Name"
                id="primaryOccupantLastName"
                v-model="searchParams.primaryOccupantLastName"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-3 col-sm-12 form-group text-left">
              <DateInput
                vid="arrivalDate"
                rules="isDate"
                name="Arrival Date"
                id="arrivalDate"
                v-model="searchParams.arrivalDate"
              />
            </div>
            <div class="col-md-3 col-sm-12 form-group text-left">
              <DateInput
                vid="departureDate"
                rules="isDate|departureDate:@arrivalDate"
                name="Departure Date"
                :dateDisabledFn="minDepartureDate"
                :initialDate="initialDate"
                id="departureDate"
                v-model="searchParams.departureDate"
              />
            </div>
            <div class="col-md-3 col-sm-12 form-group text-left">
              <DateInput
                vid="transactionDate"
                rules="isDate|isTodayOrPrior"
                name="Transaction Date"
                id="transactionDate"
                v-model="searchParams.transactionDate"
                :dateDisabledFn="maxTransactionDate"
              />
            </div>
            <div class="col-md-3 col-sm-12 form-group text-left">
              <DateInput
                vid="occupancyDate"
                rules="isDate"
                name="Occupancy Date"
                id="occupancyDate"
                v-model="searchParams.occupancyDate"
              />
              <small
                >Enter the date to search reservations made for occupying date
                entered</small
              >
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-3 col-sm-12 form-group text-left">
              <label for="region">
                <span>Region</span>
              </label>
              <multiselect
                v-model="selectedRegions"
                label="longName"
                track-by="id"
                :multiple="true"
                id="region"
                ref="region"
                :clear-on-select="false"
                :preserve-search="true"
                open-direction="bottom"
                placeholder="Select Region(s)"
                :options="regions"
                @open="openedRegions"
                :loading="loadingRegions"
              >
                <template slot="tag" slot-scope="{ option, remove }">
                  <span class="multiselect__tag" :key="option.id">
                    <span>{{ option.longName }}</span>
                    <i
                      tabindex="1"
                      @keypress.enter.prevent="remove(option)"
                      @mousedown.prevent="remove(option)"
                      class="multiselect__tag-icon"
                    ></i>
                  </span>
                </template>
              </multiselect>
            </div>
            <div class="col-md-3 col-sm-12 form-group text-left">
              <label for="parks">
                <span>Park</span>
              </label>
              <multiselect
                v-model="selectedParks"
                label="longName"
                track-by="id"
                :multiple="true"
                id="parks"
                ref="parks"
                :clear-on-select="false"
                :preserve-search="true"
                open-direction="bottom"
                placeholder="Select Park(s)"
                :options="parks"
                @open="openedParks"
                :loading="loadingParks"
              >
                <template slot="tag" slot-scope="{ option, remove }">
                  <span class="multiselect__tag" :key="option.id">
                    <span>{{ option.longName }}</span>
                    <i
                      tabindex="1"
                      @keypress.enter.prevent="remove(option)"
                      @mousedown.prevent="remove(option)"
                      class="multiselect__tag-icon"
                    ></i>
                  </span>
                </template>
              </multiselect>
            </div>
            <div class="col-md-3 col-sm-12 form-group text-left">
              <label for="loops">
                <span>Loop</span>
              </label>
              <multiselect
                v-model="selectedLoops"
                label="name"
                track-by="id"
                :multiple="true"
                id="loops"
                ref="loops"
                :clear-on-select="false"
                :preserve-search="true"
                open-direction="bottom"
                placeholder="Select Loop(s)"
                :options="loops"
                @open="openedLoops"
                :loading="loadingLoops"
                :disabled="selectedParks.length == 0"
              >
                <template slot="tag" slot-scope="{ option, remove }">
                  <span class="multiselect__tag" :key="option.id">
                    <span>{{ option.name }}</span>
                    <i
                      tabindex="1"
                      @keypress.enter.prevent="remove(option)"
                      @mousedown.prevent="remove(option)"
                      class="multiselect__tag-icon"
                    ></i>
                  </span>
                </template>
              </multiselect>
            </div>
            <div class="col-md-3 col-sm-12 form-group text-left">
              <TextInput
                rules="alpha_num"
                name="Spot"
                id="spot"
                v-model="searchParams.spot"
              />
            </div>
          </div>
          <button class="btn btn-primary mr-2" type="submit">Search</button>
          <button class="btn" type="button" @click="reset">
            Reset
          </button>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import TextInput from "@/validation/TextInput.vue";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import DateInput from "@/validation/DateInput.vue";
import Multiselect from "vue-multiselect";
import ParkSearchService from "@/services/ParkSearchService.js";
import LocationService from "@/services/LocationService.js";
import SpotReservationService from "@/services/SpotReservationService.js";
import moment from "moment";
import { mask } from "vue-the-mask";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";

export default {
  name: "AdminReservationSearchParams",
  mixins: [checkPermissionMixin],
  directives: { mask },
  components: {
    ValidationObserver,
    TextInput,
    FormErrorAlert,
    DateInput,
    Multiselect
  },
  data() {
    return {
      errors: [],
      loadingRegions: false,
      regions: [],
      loadingParks: false,
      parks: [],
      loadingLoops: false,
      loops: [],
      loadingStatuses: false,
      statuses: [],
      selectedParks: [],
      selectedRegions: [],
      selectedLoops: [],
      selectedStatuses: []
    };
  },
  computed: {
    initialDate() {
      if (this.searchParams.arrivalDate) {
        return moment(this.searchParams.arrivalDate, "MM-DD-YYYY")
          .add(1, "days")
          .format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
    searchParams() {
      return this.$store.getters["admin/reservationSearchParams"] ?? {};
    }
  },
  methods: {
    minDepartureDate(ymd) {
      if (this.searchParams.arrivalDate) {
        return moment(
          this.searchParams.arrivalDate,
          "MM-DD-YYYY"
        ).isSameOrAfter(ymd, "day");
      } else {
        return false;
      }
    },
    maxTransactionDate(ymd) {
      return moment().isBefore(ymd, "day");
    },
    search() {
      this.$refs.reservationSearch.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.reservationSearch.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.reservationSearch.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          let searchParams = {
            ...this.searchParams,
            customerPhoneNumber: this.searchParams.customerPhoneNumber ?? null,
            reservationNumber: this.searchParams.reservationNumber
              ? this.searchParams.reservationNumber.replace(/\s/g, "")
              : null,
            transactionDate:
              this.searchParams.transactionDate != null &&
              this.searchParams.transactionDate != ""
                ? this.searchParams.transactionDate
                : null
          };
          this.$emit("search", searchParams);
        }
      });
    },
    openedRegions() {
      if (this.regions.length == 0) {
        this.loadingRegions = true;
        this.getRegions();
      }
    },
    openedParks() {
      if (this.parks.length == 0) {
        this.loadingParks = true;
        this.getParks();
      }
    },
    openedLoops() {
      if (this.loops.length == 0) {
        this.loadingLoops = true;
        this.getLoops();
      }
    },
    openedStatuses() {
      if (this.statuses.length == 0) {
        this.loadingStatuses = true;
        this.getStatuses();
      }
    },
    reset() {
      this.selectedParks = [];
      this.selectedRegions = [];
      this.selectedLoops = [];
      this.selectedStatuses = [];
      this.$emit("search", {});
    },
    async getRegions() {
      const tenantId = this.$store.getters["tenant/tenantId"];
      var parkSearchService = new ParkSearchService(null, null, tenantId);
      this.regions = await parkSearchService.getRegions();
      this.loadingRegions = false;
    },
    async getStatuses() {
      const tenantId = this.$store.getters["tenant/tenantId"];
      var reservationService = new SpotReservationService(tenantId);
      const statuses = await reservationService.getAllRervationStatuses();
      this.statuses = statuses.lookupValues;
      this.loadingStatuses = false;
    },
    async getLoops() {
      const tenantId = this.$store.getters["tenant/tenantId"];
      if (this.selectedParks.length > 0) {
        this.selectedParks.forEach(park => {
          var locationService = new LocationService(tenantId, park.id);
          locationService.getLoopsForLocation().then(response => {
            const loops = response.lookupValues;
            loops.forEach(loop => {
              if (!this.loops.some(l => l.id == loop.id)) {
                this.loops.push(loop);
              }
            });
            this.loadingLoops = false;
          });
        });
      }
    },
    async getParks() {
      const tenantId = this.$store.getters["tenant/tenantId"];
      var locationService = new LocationService(tenantId, null);
      if (this.selectedRegions.length > 0) {
        this.regionIds = this.selectedRegions.map(n => n.id);
        this.selectedRegions.forEach(region => {
          locationService.getLocationsForRegion(region.id).then(response => {
            response
              .filter(x =>
                this.checkLocationPermission("ReservationManagementView", x.id)
              )
              .forEach(location => {
                if (!this.parks.some(l => l.id == location.id)) {
                  this.parks.push(location);
                }
              });
            this.loadingParks = false;
          });
        });
      } else {
        locationService.getAllLocations().then(response => {
          this.parks = response.filter(x =>
            this.checkLocationPermission("ReservationManagementView", x.id)
          );
          if (this.$route.query.locationId) {
            this.selectedParks.push(
              this.parks.find(n => n.id == this.$route.query.locationId)
            );
          }
          this.loadingParks = false;
        });
      }
    }
  },
  watch: {
    selectedParks() {
      this.searchParams.locationIds = this.selectedParks.map(item => item.id);
      this.loops = [];
      this.selectedLoops = [];
    },
    selectedRegions() {
      this.searchParams.regionIds = this.selectedRegions.map(item => item.id);
      this.parks = [];
      this.selectedParks = [];
    },
    selectedLoops() {
      this.searchParams.loopIds = this.selectedLoops.map(item => item.id);
    },
    selectedStatuses() {
      this.searchParams.reservationStatusIds = this.selectedStatuses.map(
        item => item.id
      );
    }
  },
  mounted() {
    if (this.$route.query.locationId) {
      this.getParks();
    }
    if (this.$route.query.arrivalDate) {
      this.searchParams.arrivalDate = this.$route.query.arrivalDate;
    }
    if (this.$route.query.departureDate) {
      this.searchParams.departureDate = this.$route.query.departureDate;
    }
    this.$refs.reservationStatus.$refs.search.setAttribute(
      "autocomplete",
      "off"
    );
    this.$refs.region.$refs.search.setAttribute("autocomplete", "off");
    this.$refs.loops.$refs.search.setAttribute("autocomplete", "off");
    this.$refs.parks.$refs.search.setAttribute("autocomplete", "off");
  }
};
</script>
